<template>
  <v-card :to="link" class="mx-auto" max-width="335" outlined>
    <v-list-item two-line>
      <v-list-item-avatar rounded size="56">
        <v-avatar :color="bgcolor" size="56">
          <v-icon large :color="txtcolor">{{ icon }}</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="text-overline mb-2">{{ title }}</div>
        <v-list-item-title class="text-h5 mb-1">
          {{ quantity }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    link: String,
    bgcolor: String,
    txtcolor: String,
    icon: String,
    title: String,
    quantity: Number,
    description: String,
  },
};
</script>
